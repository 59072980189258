<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Upsell Item Orders') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="columns is-mobile m-0">
        <div class="column pt-0 pb-0 pl-0">
          <SearchForm @filter="fetchData" />
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="upsellItemOrders"
        :loading="isFetching"
        :custom-row="rowClicked"
        :pagination="pagination"
        :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        row-key="id"
        @change="handleTableChange"
      >
        <template #title="{ text }">
          {{ text }}
        </template>
        <template #providers="{ record }">
          {{ joinProviders(record.providers) }}
        </template>
        <template #status="{ record }">
          {{ $t(record.status) }}
        </template>
        <template #orderedDate="{ record }">
          {{ $filters.date(record.orderedDate, {
            format: $variables.dateFormat,
          }) }}
        </template>
        <template #deliveryDate="{ record }">
          {{ $filters.date(record.deliveryDate, {
            format: $variables.dateFormat,
          }) }}
        </template>
        <template #checkinDate="{ record }">
          {{ $filters.date(record.checkinDate, {
            format: $variables.dateFormat,
          }) }}
        </template>
        <template #property="{ record }">
          {{ getPropertyName(record.propertyId, properties) }}
        </template>
        <template #items="{ record }">
          {{ record.items }}
        </template>
        <template #cancellationReason="{ record }">
          {{ $filters.capitalizeFirst(record.cancellationReason) }}
        </template>
        <template #amount="{ record }">
          {{ `${$filters.number(record.originalTotal)} JPY` }}
        </template>
      </a-table>
    </div>
    {{ partner }}
  </div>
</template>

<i18n src="@/locales/components/upsell-item-orders.json"></i18n>

<script>
import { mapGetters } from 'vuex';
import pagination from '@/components/mixins/pagination';
import SearchForm from '@/views/upsell-item-orders/components/SearchForm';

export default {
  name: 'UpsellItemOrders',
  components: { SearchForm },
  mixins: [pagination],
  data() {
    return {
      upsellItemOrders: undefined,
      isFetching: false,
      columns: [
        {
          title: this.$t('Order ID'),
          dataIndex: 'orderId',
          key: 'orderId',
          slots: { customRender: 'orderId' },
        },
        {
          title: this.$t('Providers'),
          dataIndex: 'providers',
          key: 'providers',
          slots: { customRender: 'providers' },
          width: '15%',
        },
        {
          title: this.$t('Status'),
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: this.$t('Ordered Date'),
          key: 'orderedDate',
          dataIndex: 'orderedDate',
          slots: { customRender: 'orderedDate' },
        },
        {
          title: this.$t('Checkin Date'),
          key: 'checkinDate',
          dataIndex: 'checkinDate',
          slots: { customRender: 'checkinDate' },
        },
        {
          title: this.$t('Delivery date'),
          key: 'deliveryDate',
          dataIndex: 'deliveryDate',
          slots: { customRender: 'deliveryDate' },
        },
        {
          title: this.$t('Property'),
          key: 'property',
          dataIndex: 'property',
          slots: { customRender: 'property' },
          width: '10%',
        },
        {
          title: this.$t('#Items'),
          key: 'items',
          dataIndex: 'items',
          slots: { customRender: 'items' },
          class: 'text-align-right',
        },
        {
          title: this.$t('Cancel reason'),
          key: 'cancellationReason',
          dataIndex: 'cancellationReason',
          slots: { customRender: 'cancellationReason' },
          width: '10%',
        },
        {
          title: this.$t('Amount'),
          key: 'amount',
          dataIndex: 'amount',
          slots: { customRender: 'amount' },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      properties: 'properties',
    }),
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      const {
        filterDateType,
        fromDate, toDate,
        page,
        pageSize,
        title,
        propertyId,
        providerId,
        status,
        orderId,
      } = this.$route.query;
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('upsell-item-orders/list', {
          page,
          pageSize,
          title,
          propertyId,
          providerId,
          status,
          orderId,
          ...(filterDateType && fromDate && toDate && { [filterDateType]: { fromDate, toDate } }),
        })
        .then((resp) => {
          const { data: { rows, pagination } } = resp;
          this.upsellItemOrders = rows;
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    joinProviders(providers) {
      return providers.map((provider) => provider.name).join(', ');
    },
    getPropertyName(propertyId, properties) {
      const property = properties?.find((item) => item.newPropertyCode === propertyId);
      return property?.name || '';
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$store.dispatch('routers/addFirstLevelQuery', {
            query: this.$route.query,
            name: 'upsell-item-orders',
          });
          this.$router.push({ name: 'upsell-item-order-order-info', params: { id: record.orderId } });
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .ant-table .ant-table-title {
    padding: 8px 0;
  }

  .ant-table-body {
    min-width: 1230px;
  }

  .ant-table-tbody > tr > td {
    border-color: transparent;
  }

  .text-align-right {
    text-align: right;
    padding-right: 40px;
  }
}
</style>
