export default {
  computed: {
    rangeString() {
      if (this.range && this.range.length === 0) {
        return null;
      }

      const dateFormat = this.dateFormat ? this.dateFormat : 'MM/DD/YYYY';

      const from = this.$filters.date(this.range[0], { format: dateFormat });
      const to = this.$filters.date(this.range[1], { format: dateFormat });

      return `${from} ~ ${to}`;
    },
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      const range = { fromDate: undefined, toDate: undefined };

      if (this.range && this.range.length) {
        range.fromDate = this.$filters.date(this.range[0], { format: dateFormat });
        range.toDate = this.$filters.date(this.range[1], { format: dateFormat });
      }

      return range;
    },
  },
  methods: {
    getTimeFromISOString(dateTime) {
      if (!dateTime) return '';
      return new Date(dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    },
  },
};
