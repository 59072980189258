<template>
  <div class="upsell-item-order">
    <template v-if="!loading">
      <AdminSubMenu :menu="menu" :sub-menu="subMenu">
        {{ upsellItemOrder?.orderId }}
      </AdminSubMenu>
      <router-view />
    </template>
  </div>
</template>

<i18n src="@/locales/components/upsell-item-orders.json"></i18n>

<script>
import { mapState } from 'vuex';
import previousRoute from '@/components/mixins/previousRoute';
import AdminSubMenu from '@/components/AdminSubMenu';

export default {
  name: 'UpsellItemOrder',
  components: { AdminSubMenu },
  mixins: [previousRoute],
  data() {
    return {
      menuName: 'upsell-item-orders',
    };
  },
  computed: {
    menu() {
      return {
        query: this.previousRouteQuery,
        name: this.menuName,
        title: this.$t('Back'),
      };
    },
    subMenu() {
      return [
        {
          name: 'upsell-item-order-order-info',
          title: this.$t('Order Info'),
        },
        {
          name: 'upsell-item-order-item-list',
          title: this.$t('Items'),
        },
      ];
    },
    childrenRoutes() {
      return ['order-info', 'item-list'];
    },
    ...mapState('upsell-item-orders', ['upsellItemOrder', 'loading']),
  },
  created() {
    this.asyncData();
  },
  methods: {
    async asyncData(route = null) {
      route = route || this.$route;
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        this.upsellItemOrder = await this.$store.dispatch('upsell-item-orders/get', { id: route.params.id });
      } catch (e) {
        console.log(e);
      }
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
  },
};
</script>

<style scoped></style>
