<template>
  <div class="columns form is-mini-gap is-multiline upsell-item-filter">
    <div class="is-8 columns column upsell-item-filter__search">
      <div class="column">
        <a-input
          v-model:value="title"
          :size="size"
          :placeholder="$t('Upsell Item Title')"
          @change="debounceQuery"
        />
      </div>
      <div class="column">
        <InputProperty
          v-model:value="propertyId"
          :size="size"
          style="width: 100%"
          is-allow-clear
          @change="debounceQuery"
        />
      </div>
      <div class="column">
        <InputProvider
          v-model:value="providerId"
          :size="size"
          style="width: 100%"
          is-allow-clear
          @change="debounceQuery"
        />
      </div>
      <div class="column">
        <a-select
          v-model:value="status"
          :size="size"
          :placeholder="$t('Status')"
          allow-clear
          style="width: 100%"
          @change="debounceQuery"
        >
          <a-select-option v-for="item in statusList" :key="item.value" :value="item.value">
            {{ $t(item.label) }}
          </a-select-option>
        </a-select>
      </div>
      <div class="column">
        <a-input
          v-model:value="orderId"
          :size="size"
          :placeholder="$t('Order/Reservation ID')"
          @change="debounceQuery"
        />
      </div>
    </div>
    <div class="is-4 column columns upsell-item-filter__date">
      <div class="column is-narrow">
        <a-input-group compact>
          <a-select
            v-model:value="filterDateType"
            class="is-mobile-field"
            :placeholder="$t('Choose date')"
            style="max-width: 200px"
            :size="size"
            @change="debounceQuery"
          >
            <a-select-option
              v-for="o in dateOptions"
              :key="$t(o.label)"
              :value="o.value"
              :title="$t(o.label)"
            >
              {{ $t(o.label) }}
            </a-select-option>
          </a-select>
          <a-range-picker
            v-model:value="range"
            class="is-mobile-field"
            :size="size"
            style="width: 215px"
            :format="dateFormat"
            allow-clear
            @change="debounceQuery"
          >
            <a-input
              :value="rangeString"
              :placeholder="$t(`${dateSelectionText} range`)"
              :size="size"
              class="input-date-range"
              readonly
            >
              <template #prefix>
                <CalendarOutlined />
              </template>
            </a-input>
          </a-range-picker>
        </a-input-group>
      </div>
      <div
        v-show="propertyId || providerId || status || title || range.length || orderId"
        class="column"
      >
        <a-button
          :size="size"
          style="min-width: 110px"
          type="default"
          @click="handleClear"
        >
          {{ $t("Clear") }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<i18n src="@/locales/components/upsell-item-orders.json"></i18n>

<script>
import debounce from 'lodash/debounce';
import { CalendarOutlined } from '@ant-design/icons-vue';
import InputProperty from '@/components/InputProperty';
import InputProvider from '@/components/InputProvider';
import DateFormatMixin from '@/views/upsell-item-orders/mixins/DateFormat';
import { cleanObject } from '@/utils/util';
import { ORDER_STATUS_OPTION } from '@/config/upsell-item-orders';

export default {
  name: 'SearchForm',
  components: {
    InputProvider, InputProperty, CalendarOutlined,
  },
  mixins: [DateFormatMixin],
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter'],
  data() {
    return {
      title: undefined,
      propertyId: undefined,
      providerId: undefined,
      status: undefined,
      statusList: ORDER_STATUS_OPTION,
      dateOptions: [
        { value: 'orderedDate', label: 'Ordered Date' },
        { value: 'deliveryDate', label: 'Delivery Date' },
        { value: 'checkinDate', label: 'Checkin Date' },
      ],
      orderId: undefined,
      range: [],
      filterDateType: undefined,
    };
  },
  computed: {
    dateSelectionText() {
      if (this.filterDateType) {
        return this.dateOptions.find((object) => object.value === this.filterDateType).label;
      }
      return 'Date';
    },
  },
  mounted() {
    const propertyId = this.property ? this.property.newPropertyCode : undefined;
    this.propertyId = propertyId || this.$route.query.propertyId || undefined;
    this.providerId = this.$route.query.providerId || undefined;
    this.title = this.$route.query.title || undefined;
    this.status = this.$route.query.status || undefined;
    this.orderId = this.$route.query.orderId || undefined;
    this.range = this.$route.query.fromDate && this.$route.query.toDate
      ? [this.$route.query.fromDate, this.$route.query.toDate]
      : [];
    this.filterDateType = this.$route.query.filterDateType;
  },
  methods: {
    debounceQuery: debounce(async function debounceQuery() {
      const query = cleanObject({
        ...this.$route.query,
        title: this.title,
        propertyId: this.propertyId,
        providerId: this.providerId,
        status: this.status,
        orderId: this.orderId,
        page: 1,
        ...this.formattedRange,
        filterDateType: this.filterDateType,
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.propertyId = undefined;
      this.status = undefined;
      this.title = undefined;
      this.providerId = undefined;
      this.filterDateType = undefined;
      this.orderId = undefined;
      this.range = [];
      this.debounceQuery();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-date-range {
  border-radius: 0 5px 5px 0;
}
.upsell-item-filter {
  @media screen and (max-width: 1280px) {
    flex-direction: column;

    &__search {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
</style>
